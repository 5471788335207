<template>
  <div class="qrcodeBox publicHvCenter">
    <div class="shade" @click="closeQrcodeBox"></div>
    <div class="qrcodeImgWrap">
      <img :src="qrcodeUrl" class="qrcodeImg" alt="嗨喵悦动,嗨喵互动,二维码"/>
      <div class="desc" v-if="descList">
        <div class="descItem" v-for="(item, index) in descList" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'qrcodeBox',
  components: {},
  props: {
    qrcodeUrl: {
      type: String,
      default: require('../assets/image/Code2.jpg'),
    },
    descList: Array,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    closeQrcodeBox() {
      this.$emit('closeQrcodeBox');
    },
  },
};
</script>
<style lang="less" scoped>
.qrcodeBox {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .qrcodeImgWrap {
    width: 322.5px;
    height: 322.5px;
    z-index: 1;
    position: absolute;
    .qrcodeImg {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .desc {
      width: 100%;
      position: absolute;
      bottom: -37.5px;
      background-color: #ffffff;
      .descItem {
        font-size: 18px;
        color: #666666;
        font-weight: 400;
        margin-bottom: 4.5px;
        text-align: center;
      }
    }
  }
}
</style>
