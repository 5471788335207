<template>
  <div class="home">
    <div class="header publicHvCenter">
      <img
        v-lazy="require('@/assets/annualMeetingHome/logoAnnual.png')"
        alt="嗨喵悦动,嗨喵互动,logo"
        class="logoImg"
        @click="toPurchase"
      />
      <div class="version publicHvCenter">年会版</div>
    </div>
    <div class="poster"></div>
    <div class="compare publicHvCenter">
      <img src="@/assets/image/phone/annualMeeting/vsImg.png" class="vsImg" alt="嗨喵悦动,嗨喵互动,年会套餐"/>
    </div>
    <div class="setMeal publicVCenter">
      <div class="title publicHvCenter">热门年会套餐</div>
      <div class="listWrap">
        <div class="cardItem" v-for="item in setMeals" :key="item.id">
          <div class="previewArea">
            <img
              v-lazy="item.cover"
              alt="嗨喵悦动,嗨喵互动,婚庆,酒吧,企业"
              srcset=""
              class="previewImg"
            />
          </div>
          <div class="tit">{{ item.name }}</div>
          <div class="purchase publicVCenter">
            <div class="price">
              <div class="now">￥{{ item.nowPrice }}<label>/场</label></div>
              <div class="old">￥{{ item.oldPrice }}/场</div>
            </div>
            <div class="btn publicHvCenter" @click="toPurchase">立即购买</div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommend publicVCenter">
      <div class="title publicHvCenter">年会玩法推荐</div>
      <div class="listWrap">
        <div class="listItem" v-for="item in annualProcess" :key="item.id">
          <div class="lTit publicHvCenter">
            <img
              src="@/assets/image/phone/annualMeeting/leftDesc.png"
              class="dec"
            />
            {{ item.title }}
            <img
              src="@/assets/image/phone/annualMeeting/rightDesc.png"
              class="dec"
            />
          </div>
          <div class="subList">
            <div
              class="subItem publicVCenter"
              v-for="subItem in item.contents"
              :key="subItem.id"
            >
              <div class="preview">
                <img
                  v-lazy="subItem.cover"
                  alt="嗨喵悦动,嗨喵互动,婚庆,酒吧,企业"
                  srcset=""
                  class="previewImg"
                />
              </div>
              <div class="name">{{ subItem.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chooseReason publicVCenter">
      <div class="title publicHvCenter">为什么选择嗨喵</div>
      <div class="reasons">
        <div class="reasonItem publicHvCenter">
          <div class="model"></div>
          <div class="feature">专业稳定</div>
          <div class="featureDetail">
            <div class="detailItem">高性能服务器，超稳超安全</div>
            <div class="detailItem">专业工程师、客服人员24小时在线</div>
            <div class="detailItem">领先对手不止一步</div>
          </div>
        </div>
        <div class="reasonItem publicHvCenter">
          <div class="model"></div>
          <div class="feature">操作便捷</div>
          <div class="featureDetail">
            <div class="detailItem">操作简单，极致体验，一分钟创建</div>
            <div class="detailItem">活动。一台联网电脑，一部手机</div>
            <div class="detailItem">轻松应对各种场合</div>
          </div>
        </div>
        <div class="reasonItem publicHvCenter">
          <div class="model"></div>
          <div class="feature">全部免费</div>
          <div class="featureDetail">
            <div class="detailItem">18款互动玩法不重样，专业功能</div>
            <div class="detailItem">全部免费!免费!免费!</div>
          </div>
        </div>
      </div>
    </div>
    <div class="annualLive publicVCenter">
      <div class="title publicHvCenter">年会现场</div>
      <div class="liveList">
        <div class="liveItem" v-for="item in classicCase" :key="item.id">
          <img
            v-lazy="item.cover"
            alt="嗨喵悦动,嗨喵互动,婚庆,酒吧,企业"
            srcset=""
            class="liveImg"
          />
        </div>
      </div>
    </div>
    <div class="case publicVCenter">
      <div class="title publicHvCenter">TA们都在使用嗨喵</div>
      <div class="caseList">
        <div class="caseItem" v-for="(item, index) in corPic" :key="index">
          <img
            v-lazy="item"
            alt="嗨喵悦动,嗨喵互动,婚庆,酒吧,企业"
            srcset=""
            class="caseImg"
          />
        </div>
      </div>
    </div>
    <div class="footer"></div>
    <div class="floatMenu">
      <div class="left publicHvCenter" @click="contactUs">
        <img
          src="@/assets/image/phone/annualMeeting/contactIcon.png"
          class="contactIcon"
        />
        在线咨询
      </div>
      <div class="right publicHvCenter">
        <a href="tel:400-082-2298"></a>
        <img
          src="@/assets/image/phone/annualMeeting/phoneIcon.png"
          class="phoneIcon"
        />
        电话咨询
      </div>
    </div>
    <qrcodeBox
      @closeQrcodeBox="closeQrcodeBox"
      v-if="qrcodeBoxVisible"
      :qrcodeUrl="qrcodeUrl"
      :descList="descList"
    />
  </div>
</template>

<script>
import qrcodeBox from '@/components/qrcodeBox.vue';

const SETMEALS = [
  {
    id: 0,
    cover: require('../../assets/annualMeetingHome/setMeal2.gif'),
    name: '年会套餐',
    nowPrice: 800,
    oldPrice: 1988,
  },
  {
    id: 1,
    cover: require('../../assets/annualMeetingHome/setMeal5.gif'),
    name: '手写签到',
    nowPrice: 200,
    oldPrice: 488,
  },
  {
    id: 2,
    cover: require('../../assets/annualMeetingHome/setMeal3.gif'),
    name: '巨幕抽奖',
    nowPrice: 200,
    oldPrice: 488,
  },
  {
    id: 3,
    cover: require('../../assets/annualMeetingHome/setMeal1.gif'),
    name: '宏包游戏',
    nowPrice: 500,
    oldPrice: 588,
  },
  {
    id: 4,
    cover: require('../../assets/annualMeetingHome/setMeal4.gif'),
    name: '狼吞虎咽',
    nowPrice: 200,
    oldPrice: 688,
  },
  {
    id: 5,
    cover: require('../../assets/annualMeetingHome/setMeal6.gif'),
    name: '祝福上墙',
    nowPrice: 100,
    oldPrice: 500,
  },
];
const ANNUALPROCESS = [
  {
    id: 0,
    title: '扫码签到',
    contents: [
      {
        id: '00',
        name: '签到薄签到',
        cover: require('../../assets/annualMeetingHome/signBook.jpg'),
      },
      {
        id: '01',
        name: '3D签到',
        cover: require('../../assets/annualMeetingHome/threeDSign.jpg'),
      },
      {
        id: '02',
        name: '手写签到',
        cover: require('../../assets/annualMeetingHome/xsSign.jpg'),
      },
    ],
  },
  {
    id: 1,
    title: '暖场互动',
    contents: [
      {
        id: '10',
        name: '倒计时',
        cover: require('../../assets/annualMeetingHome/djs.jpg'),
      },
      {
        id: '11',
        name: '祝福榜',
        cover: require('../../assets/annualMeetingHome/zfb.jpg'),
      },
      {
        id: '12',
        name: '超级霸屏',
        cover: require('../../assets/annualMeetingHome/superDanmu.jpg'),
      },
    ],
  },
  {
    id: 2,
    title: '宏包抽奖',
    contents: [
      {
        id: '20',
        name: '3D抽奖',
        cover: require('../../assets/annualMeetingHome/dLottery.jpg'),
      },
      {
        id: '21',
        name: '巨幕抽奖',
        cover: require('../../assets/annualMeetingHome/jmLottery.jpg'),
      },
      {
        id: '22',
        name: '宏包墙',
        cover: require('../../assets/annualMeetingHome/hbWall.jpg'),
      },
    ],
  },
  {
    id: 3,
    title: '游戏竞技',
    contents: [
      {
        id: '30',
        name: '虎年滑雪',
        cover: require('../../assets/annualMeetingHome/tigerShake.jpg'),
      },
      {
        id: '31',
        name: '武松打虎',
        cover: require('../../assets/annualMeetingHome/wusong.png'),
      },
      {
        id: '32',
        name: '狼吞虎咽',
        cover: require('../../assets/annualMeetingHome/pigout.jpg'),
      },
    ],
  },
];
const CLASSICCASE = [
  {
    id: 0,
    cover: require('../../assets/annualMeetingHome/live1.jpg'),
  },
  {
    id: 1,
    cover: require('../../assets/annualMeetingHome/live2.jpg'),
  },
  {
    id: 2,
    cover: require('../../assets/annualMeetingHome/live3.png'),
  },
  {
    id: 3,
    cover: require('../../assets/outsidePic/Rectangle 23.png'),
  },
];
const CORPIC = [
  require('../../assets/annualMeetingHome/e1.png'),
  require('../../assets/annualMeetingHome/e2.png'),
  require('../../assets/annualMeetingHome/e3.png'),
  require('../../assets/annualMeetingHome/e4.png'),
  require('../../assets/annualMeetingHome/e5.png'),
  require('../../assets/annualMeetingHome/e6.png'),
  require('../../assets/annualMeetingHome/e7.png'),
  require('../../assets/annualMeetingHome/e8.png'),
  require('../../assets/annualMeetingHome/e9.png'),
  require('../../assets/annualMeetingHome/e10.png'),
];

export default {
  name: 'phoneAnnualMeeting',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  data() {
    return {
      qrcodeBoxVisible: false,
      setMeals: SETMEALS,
      annualProcess: ANNUALPROCESS,
      classicCase: CLASSICCASE,
      corPic: CORPIC,
      qrcodeUrl: require('../../assets/annualMeetingHome/kefu.png'),
      descList: ['扫描添加客户经理', '获取最佳活动方案'],
    };
  },
  components: {
    qrcodeBox,
  },
  computed: {},
  created() {},
  methods: {
    closeQrcodeBox() {
      this.qrcodeBoxVisible = false;
    },
    contactUs() {
      this.qrcodeBoxVisible = true;
    },
    toPurchase() {
      this.$router.push({
        path: '/phoneLogin',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home::v-deep {
  position: relative;
  .header {
    height: 9.33vw;
    position: relative;
    .logoImg {
      width: 10.67vw;
      height: 6.13vw;
      position: absolute;
      left: 3.47vw;
    }
    .version {
      width: 19.2vw;
      height: 6.4vw;
      background: linear-gradient(0deg, #f94928 0%, #fc674d 100%);
      border-radius: 3.2vw;
      opacity: 0.9;
      font-size: 4vw;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.9);
      text-shadow: 0vw -0.8vw 0.27vw 0vw #ed2719;
    }
  }
  .poster {
    height: 56.27vw;
    background-image: url("../../assets/annualMeetingHome/annualMain.png");
    background-size: contain;
    position: relative;
  }
  .compare {
    height: 72vw;
    background-color: #f66a6a;
    margin-top: -1.5vw;
    .vsImg {
      width: 91.47vw;
      height: 55.73vw;
    }
  }
  .setMeal {
    flex-direction: column;
    background-color: #f66a6a;
    padding-bottom: 8.53vw;
    .title {
      width: 42.67vw;
      height: 10.67vw;
      background-image: url("../../assets/image/phone/annualMeeting/titBox.png");
      background-size: 100% 100%;
      font-size: 4.8vw;
      font-weight: 400;
      color: #ffffff;
    }
    .listWrap {
      margin-top: 4vw;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 4.27vw;
      .cardItem {
        width: 44.27vw;
        height: 51.2vw;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        padding-top: 2.16vw;
        position: relative;
        margin-bottom: 4.27vw;
        .previewArea {
          width: 40vw;
          height: 26.13vw;
          background: #009944;
          position: relative;
          .previewImg {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
        .tit {
          font-size: 3.73vw;
          font-weight: 500;
          color: #333333;
          margin-top: 3.17vw;
          width: 100%;
          padding: 0 2.13vw;
        }
        .purchase {
          justify-content: space-between;
          position: absolute;
          width: 100%;
          bottom: 2vw;
          padding: 0 2.13vw;
          .price {
            .now {
              font-size: 4.8vw;
              font-weight: 500;
              color: #fc2828;
              label {
                color: #333333;
                font-size: 3.2vw;
              }
            }
            .old {
              font-size: 3.2vw;
              font-weight: 400;
              text-decoration: line-through;
              color: #666666;
              margin-top: 0.4vw;
              padding-left: 0.6vw;
            }
          }
          .btn {
            width: 17.07vw;
            height: 6.13vw;
            background: #fc2828;
            border-radius: 3.2vw;
            font-size: 3.2vw;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
  .recommend {
    flex-direction: column;
    background-color: #f66a6a;
    padding-bottom: 8.53vw;
    .title {
      width: 42.67vw;
      height: 10.67vw;
      background-image: url("../../assets/image/phone/annualMeeting/titBox.png");
      background-size: 100% 100%;
      font-size: 4.8vw;
      font-weight: 400;
      color: #ffffff;
    }
    .listWrap {
      margin-top: 4vw;
      padding: 0 4.27vw;
      width: 100%;
      .listItem {
        margin-top: 5.33vw;
        .lTit {
          width: 100%;
          font-size: 4.8vw;
          font-weight: 400;
          color: #ffffff;
          .dec {
            width: 4.2vw;
            height: 4.2vw;
            &:nth-child(1) {
              margin-right: 2.4vw;
            }
            &:nth-child(2) {
              margin-left: 2.4vw;
            }
          }
        }
        .subList {
          width: 100%;
          margin-top: 4.27vw;
          display: flex;
          justify-content: space-between;
          .subItem {
            width: 28.53vw;
            height: 24.8vw;
            background: #ffffff;
            padding: 0 2.13vw;
            flex-direction: column;
            .preview {
              width: 28.53vw;
              height: 16vw;
              background: #009944;
              position: relative;
              .previewImg {
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }
            .name {
              font-size: 3.73vw;
              font-weight: 400;
              color: #333333;
              line-height: 8.7vw;
            }
          }
        }
      }
    }
  }
  .chooseReason {
    flex-direction: column;
    background-color: #ffffff;
    padding-bottom: 8.53vw;
    padding-top: 8.53vw;
    .title {
      width: 42.67vw;
      height: 10.67vw;
      background-image: url("../../assets/image/phone/annualMeeting/titBox.png");
      background-size: 100% 100%;
      font-size: 4.8vw;
      font-weight: 400;
      color: #ffffff;
    }
    .reasons {
      display: flex;
      width: 100%;
      margin-top: 5.33vw;
      .reasonItem {
        width: 33.3333%;
        height: 19.2vw;
        background-size: 100% 100%;
        flex-direction: column;
        position: relative;
        .model {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
        }
        .feature {
          color: #ffffff;
          font-size: 2.8vw;
          font-weight: bold;
          position: relative;
        }
        .featureDetail {
          margin-top: 1.5vw;
          position: relative;
          .detailItem {
            font-size: 1.8vw;
            color: #ffffff;
            text-align: center;
          }
        }
        &:nth-child(1) {
          background-image: url("../../assets/newhome/bg1.jpg");
        }
        &:nth-child(2) {
          background-image: url("../../assets/newhome/bg2.jpg");
        }
        &:nth-child(3) {
          background-image: url("../../assets/newhome/bg3.jpg");
        }
      }
    }
  }
  .annualLive {
    flex-direction: column;
    background-color: #f66a6a;
    padding-bottom: 8.53vw;
    padding-top: 8.42vw;
    .title {
      width: 42.67vw;
      height: 10.67vw;
      background-image: url("../../assets/image/phone/annualMeeting/titBox.png");
      background-size: 100% 100%;
      font-size: 4.8vw;
      font-weight: 400;
      color: #ffffff;
    }
    .liveList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 4.27vw;
      margin-top: 5.33vw;
      .liveItem {
        width: 44.27vw;
        height: 24.8vw;
        background: #ffffff;
        margin-bottom: 2.93vw;
        position: relative;
        .liveImg {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .case {
    flex-direction: column;
    background-color: #1c2339;
    padding-bottom: 4.53vw;
    padding-top: 8.42vw;
    .title {
      width: 42.67vw;
      height: 10.67vw;
      background-image: url("../../assets/image/phone/annualMeeting/titBox.png");
      background-size: 100% 100%;
      font-size: 4.6vw;
      font-weight: 400;
      color: #ffffff;
    }
    .caseList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 4.27vw;
      margin-top: 5.33vw;
      .caseItem {
        width: 16.53vw;
        height: 16.53vw;
        background: #ffffff;
        margin-bottom: 2.13vw;
        position: relative;
        .caseImg {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer {
    height: 18.4vw;
    background-color: #f66a6a;
  }
  .floatMenu {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    a {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .left {
      font-size: 3.73vw;
      font-weight: 500;
      color: #ffd16c;
      width: 50%;
      height: 12.8vw;
      background: #ffffff;
      position: relative;
      .contactIcon {
        width: 4.8vw;
        height: 4vw;
        margin-right: 1.87vw;
      }
    }
    .right {
      font-size: 3.73vw;
      font-weight: 500;
      color: #ffffff;
      width: 50%;
      height: 12.8vw;
      background: #ffd16c;
      position: relative;
      .phoneIcon {
        width: 4.8vw;
        height: 4.27vw;
        margin-right: 1.87vw;
      }
    }
  }
  .qrcodeBox {
    .qrcodeImgWrap {
      width: 68vw;
      height: 68vw;
      .desc {
        bottom: -13vw;
        .descItem {
          font-size: 4.2vw;
          margin-bottom: 2vw;
        }
      }
    }
  }
}
</style>
